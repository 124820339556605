var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});

function isTLSSocket(socket) {
  return socket.encrypted;
}

const deferToConnect = (socket, fn) => {
  let listeners;

  if (typeof fn === "function") {
    const connect = fn;
    listeners = {
      connect
    };
  } else {
    listeners = fn;
  }

  const hasConnectListener = typeof listeners.connect === "function";
  const hasSecureConnectListener = typeof listeners.secureConnect === "function";
  const hasCloseListener = typeof listeners.close === "function";

  const onConnect = () => {
    if (hasConnectListener) {
      listeners.connect();
    }

    if (isTLSSocket(socket) && hasSecureConnectListener) {
      if (socket.authorized) {
        listeners.secureConnect();
      } else if (!socket.authorizationError) {
        socket.once("secureConnect", listeners.secureConnect);
      }
    }

    if (hasCloseListener) {
      socket.once("close", listeners.close);
    }
  };

  if (socket.writable && !socket.connecting) {
    onConnect();
  } else if (socket.connecting) {
    socket.once("connect", onConnect);
  } else if (socket.destroyed && hasCloseListener) {
    listeners.close(socket._hadError);
  }
};

exports.default = deferToConnect; // For CommonJS default export support

exports = deferToConnect;
exports.default = deferToConnect;
export default exports;
export const __esModule = exports.__esModule;